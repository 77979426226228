<template src="./Index.html" />

<script>
import {
  BButton,
  BCard,
  BCol,
  BOverlay,
  BPagination,
  BRow,
  BFormGroup,
  BForm,
  BButtonGroup,
  BContainer,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'StudentScheduleV2',
  components: {
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BOverlay,
    BPagination,
    BRow,
    BFormGroup,
    BForm,
    BContainer,
    vSelect,
  },

  data() {
    return {
      isLoading: false,
      user: getUser(),
      filter: {
        currentPage: 1,
        itemsPerPage: 100,
        organizationId: '',
        courseId: '',
        courseSemesterId: '',
        studentId: '',
      },
      currentMainData: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      table: {
        columns: [
          {
            label: '#',
            field: 'rowNum',
            thClass: 'text-center',
            width: '80px',
            tdClass: 'text-left',
          },
          {
            label: 'Thứ 2',
            field: 'T2',
            thClass: 'text-center',
          },
          {
            label: 'Thứ 3',
            field: 'T3',
            thClass: 'text-center',
          },
          {
            label: 'Thứ 4',
            field: 'T4',
            thClass: 'text-center',
          },
          {
            label: 'Thứ 5',
            field: 'T5',
            thClass: 'text-center',
          },
          {
            label: 'Thứ 6',
            field: 'T6',
            thClass: 'text-center',
          },
          {
            label: 'Thứ 7',
            field: 'T7',
            thClass: 'text-center',
          },
          {
            label: 'Chủ nhật',
            field: 'CN',
            thClass: 'text-center',
          },
        ],
        dataRows: [],
      },

    }
  },
  computed: {
    ...mapGetters({
      resourceName: 'creditClass/resourceName',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      programmes: 'dropdown/programmes',
      programmeSubjects: 'dropdown/programmeSubjects',
      teachers: 'dropdown/teachers',
      classes: 'dropdown/classes',
      students: 'dropdown/studentClasses',
      turns: 'dropdown/turns',
      studentSchedule: 'schedule/schedulers',
      schedulers: 'schedule/schedulers',
    }),
    courseId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.courseId : null
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.filter.organizationId = this.user.orgId
      this.filter.studentId = this.user.studentId
      this.filter.courseId = this.courseId
      await Promise.all([
        this.getCourses({ studentId: this.user.studentId }),
        this.getCourseSemesters({ courseId: this.filter.courseId }),
        this.getTurns(),
      ])
      if (this.courseSemesters.length > 0) {
        this.filter.courseSemesterId = this.courseSemesters[0].value
      }
      if (this.turns.length > 0) {
        this.turns.forEach((turn, index) => {
          const item = {
            rowNum: turn.label,
            T2: '',
            T3: '',
            T4: '',
            T5: '',
            T6: '',
            T7: '',
            CN: '',
          }
          this.table.dataRows.push(item)
        })
      }
      await this.onSearchMainData()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCoursesByStudentId',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getProgrammes: 'dropdown/getProgrammes',
      getProgrammeSubjects: 'dropdown/getProgrammeSubjects',
      createPlanData: 'creditClass/createPlanData',
      getTeacher: 'dropdown/getTeachers',
      getClasses: 'dropdown/getClasses',
      getStudents: 'dropdown/getStudentClasses',
      getTurns: 'dropdown/getTurns',
      getSchedulers: 'schedule/getSchedulersV3',
    }),

    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({ courseId: this.filter.courseId })
    },

    async onSearchMainData() {
      this.table.dataRows = []
      await this.getSchedulers(this.filter)
      const result = []
      if (this.schedulers.length > 0) {
        this.schedulers.forEach(item => {
          const days = item.groupDayIds
          const arDay = days.split(',')
          const turns = item.groupTurnIds
          const arTurnItem = turns.split('|')
          for (let i = 0; i < arTurnItem.length; i++) {
            const arTurn = arTurnItem[i].split(',')
            for (let j = 0; j < arTurn.length; j++) {
              const temp = {
                turn: arTurn[j],
                days: arDay[i],
                subjectName: item.subjectName,
                room: item.roomName,
              }
              result.push(temp)
            }
          }
        })
        console.log(result)
      }
      if (this.turns.length > 0) {
        this.turns.forEach((turn, index) => {
          let t2 = ''
          let t3 = ''
          let t4 = ''
          let t5 = ''
          let t6 = ''
          let t7 = ''
          let cn = ''
          for (let i = 0; i < result.length; i++) {
            if (index + 1 === +result[i].turn) {
              const val = `${result[i].subjectName} - ${result[i].room}`
              if (result[i].days === '1') {
                t2 = val
              }
              if (result[i].days === '2') {
                t3 = val
              }
              if (result[i].days === '3') {
                t4 = val
              }
              if (result[i].days === '4') {
                t5 = val
              }
              if (result[i].days === '5') {
                t6 = val
              }
              if (result[i].days === '6') {
                t7 = val
              }
              if (result[i].days === '7') {
                cn = val
              }
            }
          }
          const item = {
            rowNum: turn.label,
            T2: t2,
            T3: t3,
            T4: t4,
            T5: t5,
            T6: t6,
            T7: t7,
            CN: cn,
          }
          this.table.dataRows.push(item)
        })
      }
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
